<template>
    <div class="out_container">
        <v-snackbar
                timeout="2000"
                :value="snackbarText"
                color="#4CAF50"
                absolute
                top
                v-model="snackbarShow"
        >{{ snackbarText }}
        </v-snackbar>
        <v-card elevation="1">
            <div class="table_out">
                <!--筛选框-->
                <div class="d-flex">
                    <!--筛选-->
                    <div class="selects mr-4">
                        <div class="mr-2">区域选择</div>
                        <el-select v-model="stateValue1" placeholder="全部">
                            <el-option
                                    v-for="item in stateOptions"
                                    :value="item.provinceCode"
                                    :label="item.provinceName">
                            </el-option>
                        </el-select>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="#1E88E5"
                            style="font-weight: bold"
                            dark
                            class="mt-6"
                            @click="addBt">
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                        新增
                    </v-btn>
                </div>
                <!--用户表格-->
                <div class="mt-2">
                    <el-table
                            :data="tableData"
                            max-height="900"
                            style="width: 100%;height:700px;">

                        <el-table-column
                                prop="userName"
                                label="姓名"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="birthday"
                                label="出生日期">
                        </el-table-column>
                        <el-table-column
                                prop="sex"
                                label="性别">
                            <template slot-scope="scope">
                                <span>{{ scope.row.sex === 0 ? '女' : '男' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="phone"
                                label="电话">
                        </el-table-column>
                        <el-table-column
                                prop="email"
                                label="邮箱">
                        </el-table-column>
                        <el-table-column
                                prop="identity"
                                label="身份证号">
                        </el-table-column>
                        <el-table-column
                                prop="managerArea"
                                label="管理辖区">
                        </el-table-column>
                        <el-table-column
                                prop="role"
                                label="角色">
                        </el-table-column>
                        <el-table-column
                                prop="status"
                                label="账号状态">
                            <template slot-scope="scope">
                                <el-button v-if="scope.row.enabledStatus" type="primary" plain size="mini"
                                           @click="accountStatus(scope.row.userID,scope.row.enabledStatus)">正常
                                </el-button>
                                <el-button v-else="scope.row.enabledStatus" type="danger" plain size="mini"
                                           @click="accountStatus(scope.row.userID,scope.row.enabledStatus)">冻结
                                </el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="address"
                                label="操作"
                                width="220"
                        >
                            <template slot-scope="scope">
                                <el-button plain size="mini" @click="edit(scope.row)">分配</el-button>
                                <el-button type="danger" plain size="mini" @click="deleteUser(scope.$index, scope.row)">
                                    删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <!--表脚-->
                <div class="table_footer">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="4"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size.sync="pageSize"
                            :current-page.sync="currentPage"
                            layout="total, sizes, prev, pager, next"
                            :total="this.totalPage">
                    </el-pagination>
                </div>
            </div>
        </v-card>
        <!--弹窗-->
        <!--新增用户弹窗-->
        <v-dialog
                v-model="userDialog"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ this.dialogName }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <el-form label-position="top" label-width="70px" :model="ruleForm" :rules="rules"
                                 ref="ruleForm">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="姓名" prop="name">
                                        <el-input v-model="ruleForm.name"></el-input>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="电话" prop="phone">
                                        <el-input v-model="ruleForm.phone"></el-input>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="管辖区域" >
                                        <el-select v-model="stateValue2">
                                            <el-option
                                                    v-for="item in stateOptions"
                                                    :value="item.provinceCode"
                                                    :label="item.provinceName">

                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="角色" >
                                        <el-select v-model="characterValue1" placeholder="请选择" disabled>
                                            <el-option
                                                    v-for="item in characterOptions"
                                                    :value="item.beanId"
                                                    :label="item.beanName">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                        </el-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        取消
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="save">
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="advocateDialog"
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">{{ this.dialogName }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <el-form label-position="top" label-width="70px" :model="advocateForm" :rules="rules"
                                 ref="advocateForm">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <el-form-item label="管辖区域">
                                        <el-select v-model="stateValue3">
                                            <el-option
                                                    v-for="item in stateOptions"
                                                    :value="item.provinceCode"
                                                    :label="item.provinceName">

                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <el-form-item label="角色" >
                                        <el-select v-model="characterValue2" placeholder="请选择" disabled>
                                            <el-option
                                                    v-for="item in characterOptions"
                                                    :value="item.beanId"
                                                    :label="item.beanName">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </v-col>
                            </v-row>
                        </el-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        取消
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="saveAdvocate">
                        保存
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import {
        addUser, advocateState,
        deleteUser,
        getProvince,
        getRole,
        setUserEnabled,
        userTable_o
    } from "@/network/Details/Ordinary_admin/userManage_O";

    export default {
        name: "UserManage",
        watch: {
            stateValue1(newVal) {
                if (newVal === 0) {
                    this.updateUserTable();
                } else {
                    userTable_o({
                        pageNum: this.currentPage,
                        pageSize: this.pageSize,
                        provinceCode: newVal,
                        userID: 1
                    }).then(res => {
                        if (res.status === 200) {
                            this.tableData = res.data.info
                        }
                    })
                }
            }
        },
        created() {
            //更新表格代码
            this.updateUserTable()
            //获取区域
            getProvince({}).then(res => {
                this.stateOptions = res.data
                let item = {
                    provinceCode: 0,
                    provinceName: "全部"
                }
                this.stateOptions.unshift(item)
            })
            //获取角色
            getRole({}).then(res => {
                    this.characterOptions = res.data
                }
            )
        },
        methods: {
            addBt() {
                this.dialogName = '新增管理员'
                this.userDialog = true
            },
            save() {
              this.$refs.ruleForm.validate(v=>{
                if(v){
                  addUser({
                    createdUserID: 1,
                    managerAreaCode: this.stateValue2,
                    phone: this.ruleForm.phone,
                    roleID: 2,
                    userName: this.ruleForm.name,
                  }).then(res => {
                    if (res.status === 200) {
                      this.snackbarShow = true
                      this.snackbarText = '添加成功'
                      this.updateUserTable()
                    }
                  })
                  this.userDialog = false
                }else {
                  this.$message.error('数据有误')
                }
              })



            },
            saveAdvocate() {
                let code = 0;
                if (typeof this.stateValue3 !== "number") {
                    let options = this.stateOptions;
                    for (let i = 0; i < options.length; i++) {
                        if (options[i].provinceName === this.stateValue3) {
                            code = options[i].provinceCode
                        }
                    }
                } else {
                    code = this.stateValue3;
                }
                advocateState({
                    provinceCode: code,
                    phone: this.advocatePhone,
                }).then(res => {
                    if (res.status === 200) {
                        this.snackbarShow = true;
                        this.snackbarText = '分配成功';
                        this.updateUserTable()
                    }
                })
                this.advocateDialog = false
            },

            deleteUser(index, row) {
                this.userID = row.userID
                this.$confirm('此操作将永久删除该管理员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteUser({userID: this.userID}).then(res => {
                        if (res.status === 200) {
                            this.updateUserTable();
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            edit(row) {
                this.dialogName = '分配权限'
                this.advocateDialog = true;
                this.advocateName = row.userName
                this.stateValue3 = row.managerArea
                this.advocatePhone = row.phone
            },
            closeDialog() {
                if (this.userDialog === true) {
                    this.$refs.ruleForm.resetFields();
                    this.userDialog = false
                }
                this.advocateDialog = false
                this.stateValue = ''
            },
            accountStatus(userID, flag) {
                if (flag) {
                    this.$confirm('是否冻结账号?', '提示', {
                        confirmButtonText: '冻结',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        setUserEnabled({userID: userID, enabledStatus: 0}).then(res => {
                            if (res.status === 200) {
                                this.updateUserTable()
                                this.$message({
                                    type: 'success',
                                    message: '冻结成功!'
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消操作！'
                        });
                    });
                } else {
                    this.$confirm('是否解除冻结?', '提示', {
                        confirmButtonText: '解除',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        setUserEnabled({userID: userID, enabledStatus: 1}).then(res => {
                            if (res.status === 200) {
                                this.updateUserTable()
                                this.$message({
                                    type: 'success',
                                    message: '解除成功!'
                                });
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消操作！'
                        });
                    });
                }

            },
            handleSizeChange(val) {
                this.pageSize = val
            },
            handleCurrentChange(val) {
                this.currentPage = val
            },
            updateUserTable() {
                userTable_o({
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    userID: 1
                }).then(res => {
                    if (res.status === 200) {
                        this.totalPage = res.data.total
                        this.tableData = res.data.info;
                    }
                })
            },

        },
        data() {
            // 此处自定义校验手机号码js逻辑
            var phoneReg = /^[1][3,4,5,7,8][0-9]{9}$/
            var validatePhone = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('号码不能为空'))
                }
                setTimeout(() => {
                    if (!phoneReg.test(value)) {
                        callback(new Error('格式有误'))
                    } else {
                        callback()
                    }
                }, 1000)
            }
            return {
                advocateName: '',
                advocatePhone: '',
                //页脚
                totalPage: 1,
                pageSize: 10,
                currentPage: 1,
                //表单信息
                advocateForm: {},
                ruleForm: {
                    phone: '',
                    name: '',
                },
                // 校验规则
                rules: {
                    // 校验手机号码，主要通过validator来指定验证器名称
                    phone: [{required: true, validator: validatePhone, trigger: 'blur'}],
                    name: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
                    sex: [{required: true, message: '性别不能为空', trigger: 'blur'}],
                    state: [{required: true, message: '管理辖区不能为空', trigger: 'blur'}],
                    character: [{required: true, message: '角色不能为空', trigger: 'blur'}],
                    date: [{required: true, message: '生日不能为空', trigger: 'blur'}],
                },
                dialogName: '',
                //下拉框的值
                stateValue1: '',
                stateValue2: '',
                stateValue3: '',
                sexValue: '',
                characterValue1: '普通管理员',
                characterValue2: '普通管理员',
                addBtnClick: 0,
                advocateDialog: false,
                deleteDialog: false,
                userDialog: false,
                //下拉框选项内容
                stateOptions: [{value: '沙坪坝',}, {value: '大渡口',}],
                sexOptions: [{value: '男'}, {value: '女'}],
                characterOptions: [{value: '普通管理员'}, {value: '出租人'}],
                //表格内容
                tableData: [],
                //用户ID
                userID: window.localStorage.getItem("userId"),
                //提示
                snackbarShow: '',
                snackbarText: ''
            }
        }
    }
</script>

<style scoped lang="less">
    .out_container {
        margin-top: 1%;
        width: 98%;
        margin-left: 1%;

        .table_out {
            width: 96%;
            margin-left: 3%;

            .selects {
                padding-top: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            .table_footer {
                margin-left: 65%;
                margin-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
</style>
